// ** Icon imports
import HomeIcon from "mdi-material-ui/Home";
import StorefrontIcon from "@mui/icons-material/Storefront";
import ViewListIcon from "@mui/icons-material/ViewList";
import CallIcon from "@mui/icons-material/Call";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import SocialDistanceIcon from "@mui/icons-material/SocialDistance";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LoopOutlinedIcon from "@mui/icons-material/LoopOutlined";
import DescriptionIcon from "@mui/icons-material/Description";
import ReceiptIcon from "@mui/icons-material/Receipt";
import BurstModeIcon from "@mui/icons-material/BurstMode";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import CommentIcon from "@mui/icons-material/Comment";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import EuroIcon from "@mui/icons-material/Euro";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import CategoryIcon from "@mui/icons-material/Category";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import authConfig from "../../configs/auth";

const navigation = () => {
  const user = window.localStorage.getItem(authConfig.storageUserDataKeyName);
  // console.log("ssssssssss", user);
  return user === "Administrator"
    ? [
        {
          title: "Dashboard",
          icon: HomeIcon,
          path: "/",
        },
        {
          sectionTitle: "Restaurant Management",
        },
        {
          title: "Restaurant",
          icon: StorefrontIcon,
          path: "/restaurant",
        },
        {
          title: "Restaurant Tags",
          icon: CategoryIcon,
          path: "/restaurant-tags",
        },
        {
          title: "Menu",
          icon: ViewListIcon,
          path: "/category",
        },
        {
          title: "Orders",
          icon: ShoppingCartIcon,
          path: "/orders",
        },
        {
          sectionTitle: "Delivery Management",
        },
        {
          title: "Delivery Partners",
          icon: ReceiptIcon,
          path: "/deliveryPartner",
        },
        {
          title: "Delivery Range",
          icon: SocialDistanceIcon,
          path: "/deliveryRange",
        },
        {
          title: "Delivery Fees",
          icon: DeliveryDiningIcon,
          path: "/delivery",
        },
        {
          title: "Packaging Fees",
          icon: ConnectWithoutContactIcon,
          iconImage: "Package",
          path: "/packaging-fees",
        },
        {
          title: "IVA",
          icon: EuroIcon,
          path: "/gst",
        },
        {
          sectionTitle: "Marketing & Promotions",
        },
        {
          title: "Sliders",
          icon: BurstModeIcon,
          path: "/sliders",
        },
        {
          title: "Promo Code",
          icon: LocalOfferIcon,
          path: "/promo-code",
        },
        {
          sectionTitle: "Support & Communication",
        },
        {
          title: "Restaurant Support Teams",
          icon: ManageAccountsIcon,
          path: "/vendor-support-tools",
        },
        {
          title: "Customer Support Teams",
          icon: SupportAgentIcon,
          path: "/customer-support-tools",
        },
        {
          title: "Delivery Partners Support",
          icon: SupportAgentIcon,
          path: "/delivery-support-tools",
        },
        {
          title: "Contact Us",
          icon: CallIcon,
          path: "/contactUs",
        },
        {
          title: "Get In Touch",
          icon: ConnectWithoutContactIcon,
          path: "/get-in-touch",
        },

        {
          sectionTitle: "User Engagement & Alerts",
        },

        {
          title: "Feedback and Ratings",
          icon: CommentIcon,
          path: "/feedback-ratings",
        },
        {
          title: "Notification and Alerts",
          icon: NotificationsActiveIcon,
          path: "/notification-alerts",
        },
        {
          sectionTitle: "Reports & Policies",
        },
        {
          title: "Reports",
          icon: ReceiptLongIcon,
          path: "/reports",
        },
        // {
        //   title: "Analytics",
        //   icon: AnalyticsIcon,
        //   path: "/analytics",
        // },

        {
          title: "Privacy Policy",
          icon: LockOutlinedIcon,
          path: "/privacyPolicy",
        },
        {
          title: "Return & Refund",
          icon: LoopOutlinedIcon,
          path: "/returnRefund",
        },
        {
          title: "Terms & Services",
          icon: DescriptionIcon,
          path: "/termsServices",
        },
        {
          title: "Role Management",
          icon: ManageAccountsIcon,
          path: "/role-management",
        },
      ]
    : user === "Manager / Restaurant"
    ? [
        {
          title: "Restaurant",
          icon: StorefrontIcon,
          path: "/restaurant",
        },

        {
          title: "Restaurant Tags",
          icon: CategoryIcon,
          path: "/restaurant-tags",
        },
        {
          title: "Menu",
          icon: ViewListIcon,
          path: "/category",
        },
        {
          title: "Restaurant Support Tools",
          icon: ManageAccountsIcon,
          path: "/vendor-support-tools",
        },
        {
          title: "Sliders",
          icon: BurstModeIcon,
          path: "/sliders",
        },
        {
          title: "Orders",
          icon: ShoppingCartIcon,
          path: "/orders",
        },
        {
          title: "Promo Code",
          icon: LocalOfferIcon,
          path: "/promo-code",
        },
      ]
    : user === "Account Management"
    ? [
        {
          title: "Reports",
          icon: ReceiptLongIcon,
          path: "/reports",
        },
      ]
    : user === "Customer support"
    ? [
        {
          title: "Restaurant Support Tools",
          icon: ManageAccountsIcon,
          path: "/vendor-support-tools",
        },
        {
          title: "Customer Support Tools",
          icon: SupportAgentIcon,
          path: "/customer-support-tools",
        },
        {
          title: "Delivery Partners Support Tools",
          icon: SupportAgentIcon,
          path: "/customer-support-tools",
        },
        {
          title: "Orders",
          icon: ShoppingCartIcon,
          path: "/orders",
        },
        {
          title: "Get In Touch",
          icon: ConnectWithoutContactIcon,
          path: "/get-in-touch",
        },
        {
          title: "Feedback and Ratings",
          icon: CommentIcon,
          path: "/feedback-ratings",
        },
        {
          title: "Privacy Policy",
          icon: LockOutlinedIcon,
          path: "/privacyPolicy",
        },
        {
          title: "Return & Refund",
          icon: LoopOutlinedIcon,
          path: "/returnRefund",
        },
        {
          title: "Terms & Services",
          icon: DescriptionIcon,
          path: "/termsServices",
        },
        {
          title: "Delivery Partners",
          icon: ReceiptIcon,
          path: "/deliveryPartner",
        },
      ]
    : [];
};

export default navigation;

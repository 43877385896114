import React, { Suspense } from "react";
import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import FallbackSpinner from "./@core/components/spinner";

import AuthGuard from "src/@core/components/auth/AuthGuard";
import UserLayout from "./layouts/UserLayout";
import BlankLayout from "./@core/layouts/BlankLayout";
// import BlankLayoutWithAppBar from './@core/layouts/BlankLayoutWithAppBar'
import AclGuard from "./@core/components/auth/AclGuard";
import GuestGuard from "./@core/components/auth/GuestGuard";
import { defaultACLObj } from "./configs/acl";
import AuthLayout from "./layouts/AuthLayout";
import GetInTouch from "./pages/get-in-touch";
import RoleManagement from "./pages/role-management";
import RestaurantTagsPage from "./pages/restaurant-tags";
import PackagingFees from "./pages/packagingFees";
import Reports from "./pages/reports";

const ForgotPassEmail = React.lazy(() => import("./pages/login/forgotPass"));
const VerifyOTP = React.lazy(() => import("./pages/login/verifyOtp"));
const ResetPass = React.lazy(() => import("./pages/login/resetpass"));
const HomePage = React.lazy(() => import("./pages/home"));
const LoginPage = React.lazy(() => import("./pages/login"));
const RestaurantPage = React.lazy(() => import("./pages/restaurant"));
const RestaurantDetail = React.lazy(() =>
  import("./pages/restaurant/restaurantDetail")
);
const OrderPage = React.lazy(() => import("./pages/orders"));
const OrderDetail = React.lazy(() => import("./pages/orders/orderDetail"));
const PromoCodeDetail = React.lazy(() =>
  import("./pages/promo-code/promoCodeDetail")
);
const RestaurantMenuPage = React.lazy(() => import("./pages/restuarant-menu"));
const CategoryPage = React.lazy(() => import("./pages/category"));
const CategoryMenuPage = React.lazy(() => import("./pages/category-menu"));
const CustomizeMenuCategory = React.lazy(() =>
  import("./pages/customize-menu-category")
);
const ContactUsPage = React.lazy(() => import("./pages/contactUs"));
const DeliveryPage = React.lazy(() => import("./pages/delivery"));
const DeliveryPartnerPage = React.lazy(() => import("./pages/deliveryPartner"));
const DeliveryPartnerDetail = React.lazy(() =>
  import("./pages/deliveryPartner/deliveryPartnerDetail")
);
const GSTPage = React.lazy(() => import("./pages/gst"));
const DeliveryRangePage = React.lazy(() => import("./pages/deliveryRange"));
const PrivacyPolicyPage = React.lazy(() => import("./pages/privacyPolicy"));
const ReturnRefundPage = React.lazy(() => import("./pages/returnRefund"));
const TermsServicesPage = React.lazy(() => import("./pages/termsServices"));
const PromoCode = React.lazy(() => import("./pages/promo-code"));
const VendorSupportTools = React.lazy(() =>
  import("./pages/vendor-support-tools")
);
const VendorSupportToolsDetail = React.lazy(() =>
  import("./pages/vendor-support-tools/vendorSupportToolsDetail")
);
const FeedbackRatings = React.lazy(() => import("./pages/feedback-ratings"));
const FeedbackDetail = React.lazy(() =>
  import("./pages/feedback-ratings/FeedbackDetail")
);
const NotificationAlerts = React.lazy(() => import("./pages/notification"));
const NotificationDetail = React.lazy(() =>
  import("./pages/notification/notificationDetail")
);
const CustomerSupportTools = React.lazy(() =>
  import("./pages/customer-support")
);
const CustomerSupportToolsDetail = React.lazy(() =>
  import("./pages/customer-support/customerSupportToolsDetail")
);
const CustomerSupportToolsChatDetail = React.lazy(() =>
  import("./pages/customer-support/CustomerSupportToolsChatDetail")
);
const DeliverySupportTool = React.lazy(() =>
  import("./pages/delivery-support")
);
const DeliverySupportToolsDetail = React.lazy(() =>
  import("./pages/delivery-support/deliverySupportToolsDetail")
);
const DeliverySupportToolsChatDetail = React.lazy(() =>
  import("./pages/delivery-support/DeliverySupportToolsChatDetail")
);
const SliderPage = React.lazy(() => import("./pages/slider"));
const Analytics = React.lazy(() => import("./pages/analytics"));
const Maps = React.lazy(() => import("./views/common/map/mapComponent"));
const Page401 = React.lazy(() => import("./pages/401"));
const Page404 = React.lazy(() => import("./pages/404"));

const Guard = ({ children, authGuard, guestGuard }) => {
  if (guestGuard) {
    return <GuestGuard fallback={<FallbackSpinner />}>{children}</GuestGuard>;
  } else if (!guestGuard && !authGuard) {
    return <>{children}</>;
  } else {
    return <AuthGuard fallback={<FallbackSpinner />}>{children}</AuthGuard>;
  }
};

function App() {
  const aclAbilities = defaultACLObj;

  return (
    <Suspense fallback={<FallbackSpinner />}>
      <AclGuard aclAbilities={aclAbilities}>
        <Routes>
          <Route
            element={
              <BlankLayout>
                <Outlet />
              </BlankLayout>
            }
          >
            <Route path="/401" element={<Page401 />} />
            <Route path="/404" element={<Page404 />} />

            <Route
              element={
                <AuthLayout>
                  <Outlet />
                </AuthLayout>
              }
            >
              <Route
                element={
                  <Guard guestGuard>
                    <Outlet />
                  </Guard>
                }
              >
                <Route path="/forgotpass" element={<ForgotPassEmail />}></Route>
                <Route path="/login" element={<LoginPage />}></Route>
                <Route path="/verifyotp" element={<VerifyOTP />}></Route>
                <Route path="/resetpass" element={<ResetPass />}></Route>
              </Route>
            </Route>
          </Route>

          <Route
            element={
              <UserLayout>
                <Outlet />
              </UserLayout>
            }
          >
            <Route
              element={
                <Guard authGuard>
                  <Outlet />
                </Guard>
              }
            >
              <Route path="/" element={<HomePage />} />
              <Route path="/restaurant" element={<RestaurantPage />} />
              <Route
                path="/restaurant/:restId"
                element={<RestaurantDetail />}
              />
              <Route path="/restaurant-menu" element={<RestaurantMenuPage />} />
              <Route path="/restaurant-tags" element={<RestaurantTagsPage />} />

              <Route path="/category" element={<CategoryPage />} />
              <Route
                path="/category/:categoryId"
                element={<CategoryMenuPage />}
              />
              <Route
                path="/category/:categoryId/customizeMenuCategory/:customizeMenuCategoryId"
                element={<CustomizeMenuCategory />}
              />

              <Route path="/contactUs" element={<ContactUsPage />} />
              <Route path="/delivery" element={<DeliveryPage />} />
              <Route path="/gst" element={<GSTPage />} />
              <Route path="/packaging-fees" element={<PackagingFees />} />
              <Route path="/deliveryRange" element={<DeliveryRangePage />} />
              <Route path="/privacyPolicy" element={<PrivacyPolicyPage />} />
              <Route path="/returnRefund" element={<ReturnRefundPage />} />
              <Route path="/termsServices" element={<TermsServicesPage />} />

              <Route
                path="/deliveryPartner"
                element={<DeliveryPartnerPage />}
              />
              <Route
                path="/deliveryPartner/:deliveryPartnerId"
                element={<DeliveryPartnerDetail />}
              />

              <Route path="/sliders" element={<SliderPage />} />

              <Route path="/orders" element={<OrderPage />} />
              <Route path="/orders/:orderId" element={<OrderDetail />} />

              <Route path="/promo-code" element={<PromoCode />} />
              <Route
                path="/promo-code/:promoId"
                element={<PromoCodeDetail />}
              />

              <Route
                path="/vendor-support-tools"
                element={<VendorSupportTools />}
              />
              <Route
                path="/vendor-support-tools/:vendorId"
                element={<VendorSupportToolsDetail />}
              />

              <Route path="/feedback-ratings" element={<FeedbackRatings />} />
              <Route
                path="/feedback-ratings/:feedbackId"
                element={<FeedbackDetail />}
              />

              <Route
                path="/notification-alerts"
                element={<NotificationAlerts />}
              />
              <Route
                path="/notification-alerts/:notificationId"
                element={<NotificationDetail />}
              />

              <Route
                path="/customer-support-tools"
                element={<CustomerSupportTools />}
              />
              <Route
                path="/customer-support-tools/:customerId"
                element={<CustomerSupportToolsDetail />}
              />

              <Route
                path="/customer-support-tools/Reply/:customerId"
                element={<CustomerSupportToolsChatDetail />}
              />
              <Route
                path="/delivery-support-tools"
                element={<DeliverySupportTool />}
              />
              <Route
                path="/delivery-support-tools/:customerId"
                element={<DeliverySupportToolsDetail />}
              />

              <Route
                path="/delivery-support-tools/Reply/:customerId"
                element={<DeliverySupportToolsChatDetail />}
              />

              <Route path="/reports" element={<Reports />} />

              <Route path="/map" element={<Maps />} />

              <Route path="/analytics" element={<Analytics />} />
              <Route path="/get-in-touch" element={<GetInTouch />} />
              <Route path="/role-management" element={<RoleManagement />} />
            </Route>
          </Route>

          {/* If no route found redirect it to --> /404 */}
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>
      </AclGuard>
    </Suspense>
  );
}

export default App;

import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "mdi-material-ui/Close";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { axiosInstance } from "src/network/adapter";
import { ApiEndPoints } from "src/network/endpoints";
import * as yup from "yup";
import { toastError, toastSuccess } from "src/utils/utils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import DialogConfirmation from "./DialogConfirmation";
import CustomFileUploads from "../common/customFileUploads";

const API_KEY = "AIzaSyCUALcgVmI_raOWHkRYWLY9vv-uGqrB8fM";

const DialogFormAddRestaurant = (props) => {
  const [inputValue, setInputValue] = useState("");
  const [categories, setCategories] = useState([]);
  const [restaurantTagsData, setRestaurantTagsData] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const { mode, open, toggle, dataToEdit, onSuccess, rowId } = props;
  const [loading, setLoading] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  // const [coordinates, setCoordinates] = useState({ lat: null, lng: null });
  // console.log(JSON.stringify(coordinates.lat, null, 2), "ssssssssss");

  const validationSchema = yup.object().shape({
    name: yup.string().required("Required"),
    description: yup.string().required("Required"),
    address: yup.string().required("Required"),
    country_code: yup
      .string("Must be positive numbers only")
      .required("Required")
      .matches(/^\d+$/, "Country code must be numeric only"),
    phone_number: yup
      .string("Must be positive number only")
      .required("Required")
      .typeError("Must be positive Number only"),
    start_date_and_time: yup.string().required("Required"),
    end_date_and_time: yup.string().required("Required"),
    break_start_date_and_time: yup.string().required("Required"),
    break_end_date_and_time: yup.string().required("Required"),
    email: yup
      .string()
      .required("Email is required")
      .email("Invalid email address"),
    password:
      mode == "edit"
        ? yup.string()
        : yup
            .string()
            .required("Password is required")
            .min(6, "Password must be at least 6 characters long"),
    codigoPostal: yup
      .string()
      .matches(/^\d{4}-\d{3}$/, "Invalid Código Postal format (e.g., 1150-287)")
      .required("Required"),
    latitude: yup
      .number("Must be positive number only")
      .required("Required")
      .typeError("Must be positive Number only"),
    longitude: yup
      .number("Must be positive number only")
      .required("Required")
      .typeError("Must be positive Number only"),
    restaurant_tag: yup
      .array()
      .of(yup.string())
      .min(1, "At least one tag is required")
      .required("At least one tag is required"),
  });

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      description: "",
      restaurant_catagory: [],
      address: "",
      country_code: "",
      phone_number: "",
      start_date_and_time: "",
      end_date_and_time: "",
      break_start_date_and_time: "",
      break_end_date_and_time: "",
      email: "",
      password: "",
      codigoPostal: "",
      latitude: "",
      longitude: "",
      profile_image: null,
      restaurant_tag: [],
    },
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  useEffect(() => {
    console.log("dataToEdit", dataToEdit);
    if (open) {
      setLoading(false);
      const latitude = dataToEdit?.coordinatesArray?.[0] || "";
      const longitude = dataToEdit?.coordinatesArray?.[1] || "";
      reset({
        name: dataToEdit?.name || "",
        description: dataToEdit?.description || "",
        restaurant_catagory: dataToEdit?.restaurant_catagory || "",
        address: dataToEdit?.address || "",
        country_code: dataToEdit?.country_code,
        phone_number: dataToEdit?.phone_number,
        start_date_and_time: dataToEdit?.start_date_and_time,
        end_date_and_time: dataToEdit?.end_date_and_time,
        break_start_date_and_time: dataToEdit?.break_start_date_and_time,
        break_end_date_and_time: dataToEdit?.break_end_date_and_time,
        email: dataToEdit?.email || "",
        password: "",
        codigoPostal: dataToEdit?.codigoPostal,
        latitude: latitude,
        longitude: longitude,
        profile_image: dataToEdit?.profile_image || null,
        restaurant_tag: dataToEdit?.restaurant_tag || [],
      });
      setSelectedOption(dataToEdit?.restaurant_tag || []);
      setDialogTitle(
        mode === "add" ? "Add Your Restaurant" : "Edit Restaurant Details"
      );

      // Set categories state based on dataToEdit
      setCategories(dataToEdit?.restaurant_catagory || []);
    }
  }, [dataToEdit, mode, open, reset]);

  const onSubmit = (data) => {
    const payload = new FormData();

    // Append non-file fields to the FormData

    payload.append("name", data.name);
    payload.append("description", data.description);

    // Append array items individually
    categories.forEach((category, index) => {
      payload.append(`restaurant_catagory[${index}]`, category);
    });

    // Append other fields
    payload.append("address", data.address);
    payload.append("country_code", `+${data.country_code}`);
    payload.append("phone_number", data.phone_number);
    payload.append(
      "start_date_and_time",
      new Date(data.start_date_and_time).toISOString()
    );
    payload.append(
      "end_date_and_time",
      new Date(data.end_date_and_time).toISOString()
    );
    payload.append(
      "break_start_date_and_time",
      new Date(data.break_start_date_and_time).toISOString()
    );
    payload.append(
      "break_end_date_and_time",
      new Date(data.break_end_date_and_time).toISOString()
    );
    payload.append("email", data.email);
    payload.append("password", data.password);
    payload.append("codigoPostal", data.codigoPostal);
    payload.append("coordinatesArray[0]", data.latitude);
    payload.append("coordinatesArray[1]", data.longitude);

    data?.restaurant_tag?.forEach((tag, index) => {
      payload.append(`restaurant_tag[${index}]`, tag);
    });

    // Check if file is present and append
    if (data.profile_image) {
      payload.append("profile_image", data.profile_image);
    } else {
      console.error("No file uploaded");
      return; // Exit the function if no file is uploaded
    }

    console.log("payload entries:");
    for (let pair of payload.entries()) {
      console.log(pair[0] + ": " + pair[1]);
    }

    setLoading(true);

    let apiInstance = null;

    if (mode === "edit" && dataToEdit && dataToEdit._id) {
      apiInstance = axiosInstance.patch(
        ApiEndPoints.Restaurant.edit(dataToEdit._id),
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    } else {
      apiInstance = axiosInstance.post(
        ApiEndPoints.Restaurant.create,
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    }
    apiInstance
      .then((response) => response.data)
      .then((response) => {
        onSuccess();
        toastSuccess(response.message);
        toggle();
      })
      .catch((error) => {
        toastError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleAddCategory = () => {
    if (inputValue.trim() !== "") {
      const trimmedValue = inputValue.trim();
      console.log("Adding category:", trimmedValue);
      setCategories((prevCategories) => [...prevCategories, trimmedValue]);
      setInputValue("");
    }
  };

  const handleGetCoordinates = async (postalCode) => {
    console.log(postalCode);
    if (!postalCode) return;

    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${postalCode}&key=${API_KEY}`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      if (data.status === "OK") {
        console.log("ssssssssssss", data.results[0].geometry.location);
        const location = data.results[0].geometry.location;
        setValue("latitude", location.lat);
        setValue("longitude", location.lng);
      } else {
        console.error("Geocoding error:", data);
      }
    } catch (error) {
      console.error("Error fetching coordinates:", error);
    }
  };

  useEffect(() => {
    console.log("Categories after update:", categories);
  }, [categories]);

  const [confirmationDialogLoading, setConfirmationDialogLoading] =
    useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState(null);

  // Memoized categories to prevent unnecessary re-renders
  const memoizedCategories = useMemo(() => categories, [categories]);

  const toggleConfirmationDialog = (indexToRemove) => {
    setCategoryToDelete(indexToRemove);
    setConfirmationDialogOpen((prev) => !prev);
  };

  const onConfirmDeleteRestaurant = useCallback(
    (e, indexToRemove) => {
      e?.preventDefault();
      console.log("rowId", rowId);
      setConfirmationDialogLoading(true);
      const categoryToDelete = categories[indexToRemove];
      axiosInstance
        .patch(ApiEndPoints.Restaurant.delete_category(rowId), {
          data: { categoryName: categoryToDelete },
        })
        .then((response) => response.data)
        .then((response) => {
          toggleConfirmationDialog();
          toastSuccess(response.message);
          setCategories((prevCategories) => {
            const newCategories = [...prevCategories];
            newCategories.splice(indexToRemove, 1);
            return newCategories;
          });
          console.log("response", response);
        })
        .catch((error) => {
          toastError(error);
        })
        .finally(() => {
          setConfirmationDialogLoading(false);
        });
    },
    [categories, rowId]
  );

  const fetchData = () => {
    setLoading(true);

    axiosInstance
      .get(ApiEndPoints.Restaurant_Tags.dropdown_list)
      .then((response) => {
        console.log("Tags", response);
        setRestaurantTagsData(
          response?.data.data.map((row, index) => ({
            ...row,
            id: index + 1,
          }))
        );

        console.log("debug response--------------------", response);
      })
      .catch((error) => {
        toastError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Dialog
        open={open}
        onClose={toggle}
        fullWidth
        maxWidth="sm"
        scroll="paper"
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>{dialogTitle}</Box>
          <IconButton
            aria-label="close"
            onClick={toggle}
            sx={{ color: (theme) => theme.palette.grey[500] }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{ pb: 8, px: { sx: 8, sm: 15 }, pt: { xs: 8, sm: 12.5 } }}
        >
          <form id="restaurant-form" onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <FormLabel htmlFor="name" error={Boolean(errors.name)}>
                    Name
                  </FormLabel>
                  <Controller
                    name="name"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        value={value}
                        onChange={onChange}
                        type="text"
                        placeholder="Enter Restaurant Name"
                        error={Boolean(errors.name)}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                  {errors.name && (
                    <FormHelperText sx={{ color: "error.main" }}>
                      {errors.name.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <FormLabel
                    htmlFor="description"
                    error={Boolean(errors.description)}
                  >
                    Description
                  </FormLabel>
                  <Controller
                    name="description"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        value={value}
                        onChange={onChange}
                        type="text"
                        placeholder="Enter Description"
                        error={Boolean(errors.description)}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                  {errors.description && (
                    <FormHelperText sx={{ color: "error.main" }}>
                      {errors.description.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <FormLabel htmlFor="address" error={Boolean(errors.address)}>
                    Address
                  </FormLabel>
                  <Controller
                    name="address"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        value={value}
                        onChange={onChange}
                        multiline
                        rows={4}
                        type="text"
                        placeholder="Enter your Address"
                        error={Boolean(errors.address)}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                  {errors.address && (
                    <FormHelperText sx={{ color: "error.main" }}>
                      {errors.address.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <FormLabel
                    htmlFor="country_code"
                    error={Boolean(errors.country_code)}
                  >
                    Country Code
                  </FormLabel>
                  <Controller
                    name="country_code"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        value={value}
                        onChange={(e) => {
                          const numericValue = e.target.value.replace(
                            /\D/g,
                            ""
                          );
                          onChange(numericValue);
                        }}
                        type="text"
                        placeholder="Enter Country Code"
                        error={Boolean(errors.country_code)}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                  {errors.country_code && (
                    <FormHelperText sx={{ color: "error.main" }}>
                      {errors.country_code.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <FormLabel
                    htmlFor="phone_number"
                    error={Boolean(errors.phone_number)}
                  >
                    Phone Number
                  </FormLabel>
                  <Controller
                    name="phone_number"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        value={value}
                        onChange={(e) => {
                          const numericValue = e.target.value.replace(
                            /\D/g,
                            ""
                          );
                          onChange(numericValue);
                        }}
                        type="text"
                        placeholder="Enter Phone Number"
                        error={Boolean(errors.phone_number)}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                  {errors.phone_number && (
                    <FormHelperText sx={{ color: "error.main" }}>
                      {errors.phone_number.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <FormLabel
                    htmlFor="start_date_and_time"
                    error={Boolean(errors.start_date_and_time)}
                  >
                    Start Time
                  </FormLabel>
                  <Controller
                    name="start_date_and_time"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <DatePicker
                        showTimeSelect
                        showTimeSelectOnly
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        selected={value}
                        dateFormat="h:mm aa"
                        onChange={onChange}
                        customInput={
                          <TextField
                            fullWidth
                            variant="outlined"
                            placeholder="MM/dd/yyyy"
                          />
                        }
                      />
                    )}
                  />
                  {errors.start_date_and_time && (
                    <FormHelperText sx={{ color: "error.main" }}>
                      {errors.start_date_and_time.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <FormLabel
                    htmlFor="end_date_and_time"
                    error={Boolean(errors.end_date_and_time)}
                  >
                    End Time
                  </FormLabel>
                  <Controller
                    name="end_date_and_time"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <DatePicker
                        showTimeSelect
                        showTimeSelectOnly
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        selected={value}
                        dateFormat="h:mm aa"
                        onChange={onChange}
                        customInput={
                          <TextField
                            fullWidth
                            variant="outlined"
                            placeholder="MM/dd/yyyy"
                          />
                        }
                      />
                    )}
                  />
                  {errors.end_date_and_time && (
                    <FormHelperText sx={{ color: "error.main" }}>
                      {errors.end_date_and_time.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <FormLabel
                    htmlFor="break_start_date_and_time"
                    error={Boolean(errors.break_start_date_and_time)}
                  >
                    Dinner Start Time
                  </FormLabel>
                  <Controller
                    name="break_start_date_and_time"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <DatePicker
                        showTimeSelect
                        showTimeSelectOnly
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        selected={value}
                        dateFormat="h:mm aa"
                        onChange={onChange}
                        customInput={
                          <TextField
                            fullWidth
                            variant="outlined"
                            placeholder="MM/dd/yyyy"
                          />
                        }
                      />
                    )}
                  />
                  {errors.break_start_date_and_time && (
                    <FormHelperText sx={{ color: "error.main" }}>
                      {errors.break_start_date_and_time.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <FormLabel
                    htmlFor="break_end_date_and_time"
                    error={Boolean(errors.break_end_date_and_time)}
                  >
                    Dinner Closing Time
                  </FormLabel>
                  <Controller
                    name="break_end_date_and_time"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <DatePicker
                        showTimeSelect
                        showTimeSelectOnly
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        selected={value}
                        dateFormat="h:mm aa"
                        onChange={onChange}
                        customInput={
                          <TextField
                            fullWidth
                            variant="outlined"
                            placeholder="MM/dd/yyyy"
                          />
                        }
                      />
                    )}
                  />
                  {errors.break_end_date_and_time && (
                    <FormHelperText sx={{ color: "error.main" }}>
                      {errors.break_end_date_and_time.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              {/* Email Password */}
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <FormLabel htmlFor="email" error={Boolean(errors.email)}>
                    Email
                  </FormLabel>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        value={value}
                        onChange={onChange}
                        type="text"
                        placeholder="Enter Email"
                        error={Boolean(errors.email)}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                  {errors.email && (
                    <FormHelperText sx={{ color: "error.main" }}>
                      {errors.email.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <FormLabel htmlFor="email" error={Boolean(errors.password)}>
                    Password
                  </FormLabel>
                  <Controller
                    name="password"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        value={value}
                        onChange={onChange}
                        type="text"
                        placeholder="Enter Password"
                        error={Boolean(errors.password)}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                  {errors.password && (
                    <FormHelperText sx={{ color: "error.main" }}>
                      {errors.password.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              {/* Codigo postal */}
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <FormLabel
                    htmlFor="Código Postal"
                    error={Boolean(errors.codigoPostal)}
                  >
                    Código Postal
                  </FormLabel>
                  <Controller
                    name="codigoPostal"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        type="text"
                        placeholder="Enter Codigo Postal"
                        error={Boolean(errors.codigoPostal)}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="Add"
                                onClick={() => handleGetCoordinates(value)}
                              >
                                <AddIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />

                  {errors.codigoPostal && (
                    <FormHelperText sx={{ color: "error.main" }}>
                      {errors.codigoPostal.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              {/* <Grid item xs={12}>
                <FormControl fullWidth>
                  <FormLabel
                    htmlFor="latitude"
                    error={Boolean(errors.latitude)}
                  >
                    Latitude
                  </FormLabel>
                  <Controller
                    name="latitude"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        value={value}
                        onChange={onChange}
                        type="text"
                        placeholder="Enter latitude"
                        error={Boolean(errors.latitude)}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />

                  {errors.latitude && (
                    <FormHelperText sx={{ color: "error.main" }}>
                      {errors.latitude.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <FormLabel htmlFor="longitude" error={Boolean(errors.email)}>
                    Longitude
                  </FormLabel>
                  <Controller
                    name="longitude"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        value={value}
                        onChange={onChange}
                        type="text"
                        placeholder="Enter longitude"
                        error={Boolean(errors.longitude)}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                  {errors.longitude && (
                    <FormHelperText sx={{ color: "error.main" }}>
                      {errors.longitude.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid> */}

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <FormLabel
                    htmlFor="restaurant_tag"
                    error={Boolean(errors.restaurant_tag)}
                  >
                    Restaurant Tags
                  </FormLabel>
                  <Controller
                    name="restaurant_tag"
                    control={control}
                    render={({ field: { onChange } }) => (
                      <Autocomplete
                        multiple
                        options={restaurantTagsData}
                        value={selectedOption || []} // Ensure value is always an array
                        onChange={(event, newValue) => {
                          setSelectedOption(newValue || []); // Set to an empty array if null
                          setValue(
                            "restaurant_tag",
                            (newValue || []).map((tag) => tag.tag_name)
                          );
                        }}
                        getOptionLabel={(option) => option?.tag_name || ""} // Null check
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            type="text"
                            placeholder="Enter Restaurant Tag"
                            error={Boolean(errors.restaurant_tag)}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    )}
                  />
                  {errors.restaurant_tag && (
                    <FormHelperText sx={{ color: "error.main" }}>
                      {errors.restaurant_tag.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <FormLabel
                    htmlFor="restaurant_catagory"
                    error={Boolean(errors.restaurant_catagory)}
                  >
                    Restaurant Category
                  </FormLabel>
                  <Controller
                    name="restaurant_catagory"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        type="text"
                        placeholder="Add Restaurant Category"
                        error={Boolean(errors.restaurant_catagory)}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="Add"
                                onClick={handleAddCategory}
                              >
                                <AddIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                  {errors.restaurant_catagory && (
                    <FormHelperText sx={{ color: "error.main" }}>
                      {errors.restaurant_catagory.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container xs={12} md={12} display={"flex"} py={3}>
              {memoizedCategories.map((category, index) => (
                <Grid item p={1} key={category}>
                  <Paper
                    elevation={3}
                    sx={{
                      p: 2,
                      mb: 1,
                      display: "flex",
                      alignItems: "center",
                      bgcolor: "#03577B",
                    }}
                  >
                    <Typography variant="body1" color="#FFFFFF" pl={2}>
                      {category}
                    </Typography>
                    <IconButton
                      onClick={() => toggleConfirmationDialog(index)}
                      size="small"
                      sx={{ ml: 1, color: "#FFFFFF" }}
                    >
                      <DeleteIcon sx={{ ":hover": { color: "#fc6030" } }} />
                    </IconButton>
                  </Paper>
                </Grid>
              ))}
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel
                  htmlFor="profile_image"
                  error={Boolean(errors.profile_image)}
                >
                  Restaurant Images
                </FormLabel>
                <Controller
                  name="profile_image"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <CustomFileUploads
                      multiple={false}
                      subtitle="(Max file size 3mb)"
                      minHeight="100px"
                      files={value}
                      onChange={onChange}
                      title={"Add Image"}
                      // MediaUrl={MEDIA_URL}
                    />
                  )}
                />
                {errors?.profile_image && (
                  <FormHelperText sx={{ color: "error.main" }}>
                    {errors?.profile_image?.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            size="large"
            type="submit"
            form="restaurant-form"
            variant="contained"
            loading={loading}
          >
            Submit
          </LoadingButton>
          <Button size="large" variant="outlined" onClick={toggle}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <DialogConfirmation
        loading={confirmationDialogLoading}
        title="Delete Restaurant Category"
        subtitle={
          <Typography variant="subtitle1">
            Are you sure you want to delete the category "
            <strong>{categories[categoryToDelete]}</strong>"?
          </Typography>
        }
        open={confirmationDialogOpen}
        toggle={() => toggleConfirmationDialog(null)} // Pass null here
        onConfirm={(e) => onConfirmDeleteRestaurant(e, categoryToDelete)} // Pass categoryToDelete here
      />
    </>
  );
};

export default DialogFormAddRestaurant;

import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import CircleIcon from "@mui/icons-material/Circle";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CustomDataGrid from "src/@core/components/data-grid";
import { useNavigate } from "react-router-dom";
import moment from "moment";

function TableRestaurant({
  rows,
  totalCount,
  setCurrentPage,
  currentPage,
  setPageSize,
  pageSize,
  loading,
  toggleEdit,
  toggleDelete,
  toggleView,
  onCellClick,
}) {
  // const handleCellClick = (_id) => {
  //     onCellClick(_id);
  // };

  // Reusable cell renderer function
  // const renderCellWithClick = (fieldName) => ({ row }) => (
  //     <Typography
  //         noWrap
  //         variant='body2'
  //         title={row.fieldName}
  //         onClick={() => handleCellClick(row._id)}
  //         style={{ cursor: 'pointer' }}
  //     >
  //         {row[fieldName]}
  //     </Typography>
  // );
  const navigate = useNavigate();
  const handleViewDetails = (restaurantId) => {
    navigate(`/restaurant/${restaurantId}`);
  };
  console.log("row", rows);
  return (
    <CustomDataGrid
      loading={loading}
      rowCount={totalCount}
      rows={rows}
      columns={[
        {
          field: "sr.no",
          headerName: "Sr.No",
          minWidth: 120,
          align: "left",
          renderCell: ({ row }) => {
            return (
              <div
                style={{
                  textDecoration: "none",
                  color: "rgba(58, 53, 65, 0.87)",
                }}
              >
                {row.id}
              </div>
            );
          },
        },
        {
          field: "name",
          flex: 0.2,
          minWidth: 150,
          sortable: false,
          headerName: "Name",
          // renderCell: renderCellWithClick('name')
          renderCell: ({ row }) => {
            return (
              <div
                style={{
                  textDecoration: "none",
                  color: "rgba(58, 53, 65, 0.87)",
                }}
              >
                <CircleIcon
                  style={{
                    color:
                      row.restaurant_status === "active_restaurant"
                        ? "green"
                        : "red",
                    fontSize: "12px", // Adjust size of the dot
                  }}
                /> {' '}
                {row.name}
              </div>
            );
          },
        },
        {
          field: "description",
          flex: 0.3,
          minWidth: 150,
          sortable: false,
          headerName: "Description",
          // renderCell: renderCellWithClick('description')
        },
        {
          field: "restaurant_catagory",
          flex: 0.1,
          minWidth: 200,
          sortable: false,
          headerName: "Restaurant Category",
        },
        {
          field: "address",
          minWidth: 300,
          flex: 0.5,
          sortable: false,
          headerName: "Address",
          renderCell: ({ row }) => {
            const handleAddressClick = () => {
              const googleMapsUrl = `https://www.google.com/maps?q=${row.coordinatesArray[0]},${row.coordinatesArray[1]}`;
              window.open(googleMapsUrl, "_blank");
            };

            return (
              <Typography
                noWrap
                variant="body2"
                title={row.address}
                onClick={handleAddressClick}
                style={{
                  cursor: "pointer",
                  color: "#3a3541de",
                  textDecoration: "underline",
                }}
              >
                {row.address}
              </Typography>
            );
          },
        },

        // {
        //     field: 'country_code',
        //     flex: 0.2,
        //     minWidth: 170,
        //     sortable: false,
        //     headerName: 'Country Code',
        //     // renderCell: renderCellWithClick('country_code')
        // },
        {
          field: "phone_number",
          flex: 0.2,
          minWidth: 170,
          sortable: false,
          headerName: "Phone Number",
          // renderCell: renderCellWithClick('phone_number')
          renderCell: ({ row }) => (
            <Typography
              noWrap
              title={row.coordinatesArray[0]}
              sx={{
                cursor: "pointer",
                color: "#3a3541de",
                fontSize: "0.875rem",
              }}
            >
              {row.country_code} {row.phone_number}
            </Typography>
          ),
        },
        {
          field: "start_date_and_time",
          flex: 0.4,
          minWidth: 170,
          sortable: false,
          headerName: "Start Date and Time",
          renderCell: ({ row }) => (
            <Typography
              noWrap
              title={row.coordinatesArray[0]}
              sx={{
                cursor: "pointer",
                color: "#3a3541de",
                fontSize: "0.875rem",
              }}
            >
              {moment(row?.start_date_and_time).format("LT")}
            </Typography>
          ),
        },
        {
          field: "end_date_and_time",
          flex: 0.4,
          minWidth: 170,
          sortable: false,
          headerName: "End Date and Time",
          renderCell: ({ row }) => (
            <Typography
              noWrap
              title={row.coordinatesArray[0]}
              sx={{
                cursor: "pointer",
                color: "#3a3541de",
                fontSize: "0.875rem",
              }}
            >
              {moment(row?.end_date_and_time).format("LT")}
            </Typography>
          ),
        },
        {
          field: "break_start_date_and_time",
          flex: 0.4,
          minWidth: 170,
          sortable: false,
          headerName: "Dinner Start Time",
          renderCell: ({ row }) => (
            <Typography
              noWrap
              title={row.coordinatesArray[0]}
              sx={{
                cursor: "pointer",
                color: "#3a3541de",
                fontSize: "0.875rem",
              }}
            >
              {moment(row?.break_start_date_and_time).format("LT")}
            </Typography>
          ),
        },
        {
          field: "break_end_date_and_time",
          flex: 0.4,
          minWidth: 170,
          sortable: false,
          headerName: "Dinner Closing Time",
          renderCell: ({ row }) => (
            <Typography
              noWrap
              title={row.coordinatesArray[0]}
              sx={{
                cursor: "pointer",
                color: "#3a3541de",
                fontSize: "0.875rem",
              }}
            >
              {moment(row?.break_end_date_and_time).format("LT")}
            </Typography>
          ),
        },
        {
          field: "email",
          flex: 0.2,
          minWidth: 170,
          sortable: false,
          headerName: "Email",
          // renderCell: renderCellWithClick('email')
        },
        // {
        //     field: 'coordinatesArray[0]',
        //     flex: 0.2,
        //     minWidth: 170,
        //     sortable: false,
        //     headerName: 'Latitude',
        //     renderCell: ({ row }) => <Typography noWrap variant='body2' title={row.coordinatesArray[0]} style={{ cursor: 'pointer' }}>
        //         {row.coordinatesArray[0]}
        //     </Typography>
        // },
        // {
        //     field: 'coordinatesArray[1]',
        //     flex: 0.2,
        //     minWidth: 170,
        //     sortable: false,
        //     headerName: 'Longitude',
        //     renderCell: ({ row }) => <Typography noWrap variant='body2' title={row.coordinatesArray[1]} style={{ cursor: 'pointer' }}>
        //         {row.coordinatesArray[1]}
        //     </Typography>
        // },
        {
          field: "actions",
          flex: 0,
          minWidth: 170,
          sortable: false,
          headerName: "Actions",
          renderCell: ({ row }) => (
            <Box display="flex" alignItems="center" gap="10px">
              <IconButton
                size="small"
                color="primary"
                variant="outlined"
                onClick={(e) => toggleEdit(e, "edit", row._id, row)} // Pass row._id as a parameter
              >
                <EditIcon />
              </IconButton>
              <IconButton
                size="small"
                color="secondary"
                onClick={(e) => toggleDelete(e, row)}
              >
                <DeleteIcon />
              </IconButton>
              <IconButton size="small" color="secondary">
                <RemoveRedEyeIcon onClick={() => handleViewDetails(row._id)} />
              </IconButton>
            </Box>
          ),
        },
      ]}
      currentPage={currentPage}
      pageSize={pageSize}
      setCurrentPage={setCurrentPage}
      setPageSize={setPageSize}
    />
  );
}

export default TableRestaurant;
